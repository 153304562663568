import styled from 'styled-components';
import { customMediaQuery } from 'students/views/shared/styled';

export const SWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const SErrorWrapper = styled(SWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
`;

export const SErrorTitle = styled.h2`
  color: var(--color-white);
  text-align: center;
  font-size: 1.25rem;
  padding: 0 1rem;

  ${customMediaQuery('tablet')} {
    font-size: 1.75rem;
  }
`;
