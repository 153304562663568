import React, { ComponentClass, ComponentType, PureComponent, ReactNode } from 'react';
import { Translate } from 'i18n';
import { SErrorTitle, SErrorWrapper } from '../styled';

interface IState {
  hasError: boolean;
}

export const withVideoErrorBoundary = <P extends Record<string, any>>(
  WrappedComponent: ComponentType
): ComponentClass<P> =>
  class extends PureComponent<P, IState> {
    constructor(props: P) {
      super(props);
      this.state = { hasError: false };
    }

    componentDidCatch(): void {
      this.setState({ hasError: true });
      this.props.onVideoPlayerError && this.props.onVideoPlayerError();
    }

    render(): ReactNode {
      const { hasError } = this.state;
      const { onVideoPlayerError, ...otherProps } = this.props;

      if (hasError) {
        return (
          <SErrorWrapper>
            <SErrorTitle>
              <Translate str="frontend.media_player.video_player.errors.something_went_wrong" />
            </SErrorTitle>
          </SErrorWrapper>
        );
      }

      return <WrappedComponent {...otherProps} />;
    }
  };
