import React, { useEffect, useRef } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import cn from 'classnames';
import styled from 'styled-components';

import { SWrapper } from './styled';
import { withVideoErrorBoundary } from './HOC';

const otherVideoSites = ['www.elevkanalen.no'];

interface IProps extends ReactPlayerProps {
  className?: string;
}

const VideoPlayer: React.FC<IProps> = (props) => {
  const reactPlayerRef = useRef<ReactPlayer>(null);
  const { className, ...playerProps } = props;
  const videoUrlHost = useRef<string>('');
  const playerPropsWithDefault = {
    width: '100%',
    height: '100%',
    ...playerProps
  };

  useEffect(() => {
    try {
      videoUrlHost.current = new URL(String(playerProps.url)).hostname;

      /*
       * Unsolved bug on iOS devices (shortly, iOS prevents onReady method to get called)
       * https://github.com/CookPete/react-player/issues/340
       *
       * Solved with manual calling load() function in order to then execute onReady method
       * */
      reactPlayerRef?.current?.getInternalPlayer()?.load();
    } catch (_e) {
      /* noop */
    }
  }, [playerProps.url]);

  return (
    <SWrapper className={cn(className)}>
      {otherVideoSites.includes(videoUrlHost.current) ? (
        <SIframe src={String(playerProps.url)} frameBorder="0" />
      ) : (
        <ReactPlayer
          {...playerPropsWithDefault}
          ref={reactPlayerRef}
          volume={playerProps.volume}
        />
      )}
    </SWrapper>
  );
};

const SIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

export default withVideoErrorBoundary(VideoPlayer);
