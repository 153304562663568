import { useEffect } from 'react';
import { modalSelectors } from 'students/stores/modal';
import { useTypedSelector } from 'students/stores/_utils';

function useLessonPause(onPause: () => void): void {
  const openedModals = useTypedSelector(modalSelectors.selectModalOpenedKeys);

  useEffect(() => {
    if (openedModals.length) {
      onPause();
    }
  }, [openedModals, onPause]);
}

export default useLessonPause;
