import { useCallback } from 'react';
import { useLessonPause, usePageVisibility } from 'students/views/shared/hooks';

export default function useStopOnLeave(stopCb: () => void): void {
  const onPageVisibilityChange = useCallback(
    (isHidden: boolean) => {
      isHidden && stopCb();
    },
    [stopCb]
  );
  usePageVisibility(onPageVisibilityChange);

  const stopPlaying = useCallback(() => {
    stopCb();
  }, [stopCb]);
  useLessonPause(stopPlaying);
}
